import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '~components/Container';
import RichText from '~components/RichText';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: '15px',
    paddingBottom: '15px'
  },
  root: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    padding: '0px 40px 40px',
    border: '1px #CCCCCC solid',
    borderTopWidth: 0,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px 20px',
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16
    }
  },
  header: {
    width: '100%',
    backgroundColor: '#FBFBFB',
    border: '1px #CCCCCC solid',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    [theme.breakpoints.down('xs')]: {
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16
    }
  },
  title: {
    color: 'rgba(36,36,36,0.70)',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: '700',
    textAlign: 'center',
    margin: '16px auto',
    padding: '0px 40px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px'
    }
  },
  content: {
    '& > h3': {
      color: '#222222',
      fontSize: 18,
      lineHeight: '25px',
      fontFamily: 'Inter',
      fontWeight: '700',
      marginTop: 8,
      marginBottom: 16
    },
    '& > p': {
      color: '#222222',
      fontSize: 16,
      fontFamily: 'Inter',
      lineHeight: '26px',
      fontWeight: '400',
      marginTop: 8,
      marginBottom: 8
    },
    '& > p:empty': {
      height: '8px'
    }
  }
}));

const ArticleCard = ({ card_title, card_content, containerSize }) => {
  const classes = useStyles();

  return (
    <Container maxWidth={containerSize} className={classes.container}>
      <div className={classes.header}>
        <h5 className={classes.title}>{card_title}</h5>
      </div>
      <div className={classes.root}>
        <div>
          <RichText externalClassName={classes.content} html={card_content?.html} />
        </div>
      </div>
    </Container>
  );
};

ArticleCard.propTypes = {
  card_title: PropTypes.string,
  card_content: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }),
  containerSize: PropTypes.string
};

ArticleCard.defaultProps = {
  card_title: '',
  card_content: null,
  containerSize: 'md'
};

export default ArticleCard;
